.mainBackground {
  height: 105vh;
  width: 105vw;
  padding: 0;
  background-size: cover;
  background-position: 50% 20%;
  position: absolute;
}

.homeLogo {
  padding: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  position: absolute;
}
.title {
  max-width: 95vw;
  min-width: 50vw;
  position: absolute;
  padding: 0;
  bottom: 20vh;
  left: 10vw; 
  width: 80vw;
  z-index: 1000;
  text-align: center;
  /* transform: rotateZ(10deg); */
  font-family: 宋体;
  font-weight: bolder;    
  /* text-shadow: 0 0 5px white; */

}
.join {
  max-width: 100vw;
  max-height: 50vh;
  padding: 0;
  position: absolute;
  bottom: -5px;
  left: -5px;
}
.subtitle {
  font-size: 1.1em;
  line-height: 2em;
  font-weight: 500;
}

body {
  overflow: hidden;
}

.fadein { 
  animation: fadeIn 4s;
  -webkit-animation: fadeIn 4s;
  -moz-animation: fadeIn 4s;
  -ms-animation: fadeIn 4s;
  -o-animation: fadeIn 4s;
}
.rotatein { 
  animation: rotateIn 4s;
  -webkit-animation: rotateIn 4s;
  -moz-animation: rotateIn 4s;
  -o-animation: rotateIn 4s;
  -ms-animation: rotateIn 4s;
}

@keyframes fadeIn{
  0% { opacity:0; }
  10% { opacity:0; }
  40% { opacity:1; }
  100% { opacity:1;}
}

@keyframes rotateIn{
  0% { 
    opacity:0;
    transform:  scale(0) rotate(0deg) translate3d(-50%,-50%,0);
    -o-transform:  scale(0) rotate(0deg) translate3d(-50%,-50%,0);
    -webkit-transform:  scale(0) rotate(0deg) translate3d(-50%,-50%,0);
    -ms-transform:  scale(0) rotate(0deg) translate3d(-50%,-50%,0);
    -moz-transform:  scale(0) rotate(0deg) translate3d(-50%,-50%,0);
    }
  20% {
    opacity:.5;
    transform: scale(0) rotate(0deg) translate3d(-50%,-50%,0);
    -o-transform: scale(0) rotate(0deg) translate3d(-50%,-50%,0);
    -webkit-transform: scale(0) rotate(0deg) translate3d(-50%,-50%,0);
    -ms-transform: scale(0) rotate(0deg) translate3d(-50%,-50%,0);
    -moz-transform: scale(0) rotate(0deg) translate3d(-50%,-50%,0);
  }
  50% {
    opacity:1; 
    transform: scale(1) rotate(1080deg) translate3d(-50%,-50%,0);
    -o-transform: scale(1) rotate(1080deg) translate3d(-50%,-50%,0);
    -webkit-transform: scale(1) rotate(1080deg) translate3d(-50%,-50%,0);
    -ms-transform: scale(1) rotate(1080deg) translate3d(-50%,-50%,0);
    -moz-transform: scale(1) rotate(1080deg) translate3d(-50%,-50%,0);
  }
  100% {
    opacity:1; 
    transform: scale(1) rotate(1080deg) translate3d(-50%,-50%,0);
    -o-transform: scale(1) rotate(1080deg) translate3d(-50%,-50%,0);
    -moz-transform: scale(1) rotate(1080deg) translate3d(-50%,-50%,0);
    -ms-transform: scale(1) rotate(1080deg) translate3d(-50%,-50%,0);
    -webkit-transform: scale(1) rotate(1080deg) translate3d(-50%,-50%,0);
  }
}
